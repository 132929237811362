import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { nextTuesday } from "@helpers/date";
import { useSecondModal } from "@helpers/hooks/useSecondModal";
import {useRecoilState, useRecoilValue} from "recoil";
import {
  netPriceState,
  imageCountState,
} from "@atoms/priceCalc";
import { selectedPaymentMethod, selectedShippingAddress, validCoupon, addressLinkState } from "@atoms";
import "./style.css";
import {OrderStatus} from "../../../constants/enums/orderStatus";
import {imagesLoadingState} from "../../../Recoil/Atoms";
import InputCoupon from "../../components/InputCoupon";
import AmountCharged from "../../../helpers/AmountCharged";
import {imagesBlobState} from "@atoms";
import HeartLoader from "../../HeartLoader";
import AddressLink from "../AddressLink";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const SHIPMENT_COST = 29.90;

function getFormValues() {
  const storedValues = localStorage.getItem("userShippingAddress");

  if (!storedValues)
    return {
      fullName: "",
      address1: "",
      address2: "",
      city: "",
      postalCode: "",
      phone: "",
      email: "",
      fromValidate: false,
    };
  return JSON.parse(storedValues);
}


const CheckoutMobile = (props) => {
  const history = useHistory();
  const { style } = props;
  const netPrice = useRecoilValue(netPriceState);
  const imageCount = useRecoilValue(imageCountState);
  const [numberOfImages,] = useState(5);
  const imagesBlob = useRecoilValue(imagesBlobState);
  const [shippingAddressFormValues,] = useState(getFormValues);
  const [isLoading, setLoading] = useState(false);
  const selectedAddress = useRecoilValue(selectedShippingAddress);
  const selectedPayment = useRecoilValue(selectedPaymentMethod);
  const selectedCoupon = useRecoilValue(validCoupon);
  const modal = useSecondModal();
  const amountCharged = AmountCharged(imageCount + imagesBlob.length, numberOfImages, netPrice + (imagesBlob.length * 45), SHIPMENT_COST, selectedCoupon);
  const [showAddressLink, setShowAddressLink] = useRecoilState(addressLinkState);

  useEffect(() => {
    localStorage.setItem(
      "userShippingAddress",
      JSON.stringify(shippingAddressFormValues)
    );
  }, [shippingAddressFormValues]);

  const queryParams = new URLSearchParams(window.location.search);
  const myPaymentError = queryParams.get("data");

  const [isImagesLoading, setIsImagesLoading] = useRecoilState(imagesLoadingState);
  const [isClicked, setIsClicked] = useState(false);

  const handleImagesLoading = () => {
    setIsClicked(true);
  };

  useEffect(() => {
    if (!isImagesLoading && isClicked) {
      setIsClicked(false);
      handleTranzilarPayment();
    }
  }, [isImagesLoading]);

  if (myPaymentError) {
    history.push("/review-your-images");
  }

  const openAddressPopupMobile = () => {
    modal("open", 'addAddressMobile');
  };

  const mySaveCardPopUp = () => {
    if(!selectedAddress){
      return setShowAddressLink(true);
    }
    modal("open", 'selectCardMobile');
  };


  const renderAddAddressButton = () => {
    if (selectedAddress) {
      return {
        class: cn("", { checked: selectedAddress }),
        text:
          selectedAddress.fullName +
          ", " +
          selectedAddress.city,
        img: <img src="/assets/file/images/Check.png" style={{ marginBottom: "2px" }} alt="" />,
      };
    } else {
      return {
        class: "new-link",
        text: "הוספת כתובת למשלוח",
        img: (
          <img
            src="/assets/images/form_address.png"
            style={{ marginLeft: "5px", width: "25px", height: "25px" }}
            alt="form_address"
          />
        ),
      };
    }
  };

  const creatOrder = async (data) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      }
      const res = await axios.post(`${BASE_URL}/user/createorder`, data, config);
      if (res.data.status === 200) {
        console.log('----success-----')
      }
      return res.data;
    } catch (error) {
      console.log(error)
      return null;
    }
  }

  const getCardExpDate = (month, year) => {
    const shortYear = year % 100;
    let shortYearStr = shortYear < 10 ? '0' + shortYear.toString() : shortYear.toString();
    return `${month}${shortYearStr}`;
  }

  const handleTranzilarPayment = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();

      if(localStorage.getItem("orderId")) {
        const orderId = localStorage.getItem("orderId");

        const checkRes = await axios.get(`${BASE_URL}/user/checkorder/${orderId}`);
        console.log(checkRes);

        if(checkRes.data && checkRes.data.orderStatus === OrderStatus.NOT_PAID) {
          const response = await axios.post(BASE_URL + '/payment/do', {
            card: {
              no: selectedPayment.cardNumber.replace(/\s/g, ''),
              expdate: getCardExpDate(selectedPayment.expiriedMonth, selectedPayment.expiriedYear),
              cvv: selectedPayment.cvv,
              email: selectedAddress.email,
              id: selectedPayment.id,
            },
            amount: amountCharged,
          });

          if(response.data.status !== 200){
            throw new Error('Payment failed.');
          }

          const orderData = await axios.post(`${BASE_URL}/user/changeorderstatus/${orderId}`, {
            status: OrderStatus.PAID,
            amountCharged: amountCharged,
          });
          if (orderData.data.status === 400) {
            throw new Error('Completing order failed.');
          }

          localStorage.clear();
          history.push(`/payment-success/${orderId}`);
        } else {
          console.log("Order already paid or failed");
          localStorage.removeItem("orderId");
        }
      } else {
        const response = await axios.post(BASE_URL + '/payment/do', {
          card: {
            no: selectedPayment.cardNumber.replace(/\s/g, ''),
            expdate: getCardExpDate(selectedPayment.expiriedMonth, selectedPayment.expiriedYear),
            cvv: selectedPayment.cvv,
            email: selectedAddress.email,
            id: selectedPayment.id,
          },
          amount: amountCharged,
        });

        if(response.data.status !== 200){
          throw new Error('Payment failed.');
        }

        const orderData = await creatOrder({ ...selectedAddress, uid: localStorage.getItem('uniqueUserId'), finalAmount: amountCharged, orderStatus: OrderStatus.PAID });
        if (!orderData) {
          throw new Error('Creating order failed.');
        }

        localStorage.clear();
        history.push(`/payment-success/${orderData.oid}`);
      }
    } catch (err) {
      console.log(err);
      modal("open", 'errorCart');
    } finally {
      setLoading(false);
    }
  }

  const renderCounts = () => {
    return <><span>{imageCount + imagesBlob.length} </span>בלנדס בגודל<span> 21x21</span></>
  }

  return (
    <div className="mobile-checkout-modal" style={{ ...style }}>
      <AddressLink show={showAddressLink}/>
      {isClicked && (
        <HeartLoader isLoading={isImagesLoading} showText={true} background={"white"} />
      )}
      <div
        id="mobile-checkout-wrapper"
        style={{ overflowY: "scroll" }}
      >
        <div className="">
          <div className="" id="myCartMobileContent">
            <div className="" style={{ paddingBottom: "0px" }}>
              <div className="van-wrap" style={{ verticalAligh: "middle" }}>
                <span
                  style={{
                    marginRight: "5px",
                    color: "#000000",
                    fontWeight: "400",
                    fontFamily: "rubik",
                    fontSize: "14px",
                    lineHeight: "25px",
                  }}
                >
                <img src="https://www.facebook.com/tr?id=266939557094379&ev=Cart" height="1" width="1" alt=""/>
                  אני רוצה שתארזו לי את המשלוח כמתנה
                </span>&nbsp;
                <input type="checkbox" className="mobile-cart-checkbox" />
                <img src="/assets/images/giftcart.png" style={{ width: "24px", height: "24px" }} alt="" />
              </div>
            </div>

            <div className="modal-body">
              <div style={{ direction: "rtl" }} className="checkout-name-credit-main-div">
                <span>
                  {selectedAddress ? (
                    <p
                      className={renderAddAddressButton().class.toString()}
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#addwin"
                      onClick={openAddressPopupMobile}
                      style={{ paddingTop: "26px", paddingBottom: '16px', fontWeight: 400, fontSize: "14px", verticalAlign: "middle" }}
                    >&nbsp;&nbsp;
                      {renderAddAddressButton().img}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {renderAddAddressButton().text}
                    </p>
                  ) : (
                    <p
                      className={renderAddAddressButton().class.toString()}
                      data-dismiss="modal"
                      data-toggle="modal"
                      data-target="#addwin"
                      onClick={openAddressPopupMobile}
                      style={{ paddingTop: "26px", fontWeight: 700, fontSize: "14px" }}
                    >
                      {renderAddAddressButton().img}
                      &nbsp;&nbsp;&nbsp;
                      {renderAddAddressButton().text}
                    </p>
                  )}
                </span>
                {selectedPayment ? (
                  <>
                    <span>
                      <p
                        className="new-link add-padd text-black-color"
                        style={{ color: "black", margin: "0px 0px 10px 0px", fontWeight: 400, fontSize: "14px" }}
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#addwin"
                        onClick={mySaveCardPopUp}
                      > &nbsp;&nbsp;
                        <img src="/assets/file/images/Check.png" style={{ marginBottom: "2px" }} alt="" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {selectedPayment.hebrewType + ' ' + selectedPayment.cardNumber.substring(15, 19)}
                      </p>
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <p
                        className="new-link add-padd"
                        data-dismiss="modal"
                        data-toggle="modal"
                        data-target="#addwin"
                        onClick={mySaveCardPopUp}
                        style={{ marginBottom: "10px", fontWeight: 700, fontSize: "14px" }}
                      >
                        <img
                          src="/assets/images/card-pos.svg"
                          alt=""
                        />
                        &nbsp;&nbsp;&nbsp;
                        הוספת אמצעי תשלום
                      </p>
                    </span>
                  </>
                )}
              </div>
              <div className="checkout-calculation">
                <div className="checkout-calculation__info" style={{ fontWeight: 400, marginBottom: "0px", justifyContent: "flex-end" }}>
               {imageCount + imagesBlob.length <= 4 && <div>
                  <p>
                    המשלוח צפוי להגיע אליכם עד
                    <span style={{ fontWeight: 500 }}> יום {nextTuesday()}</span>
                  </p>
                                  </div>}
                {imageCount + imagesBlob.length > 4 && <div className="mobile-free-shipping">
                  <p>
                    ההזמנה שלכם זכאית ל
                    <span style={{ fontWeight: 500 }} class="free-shipping-green-text">משלוח חינם,&nbsp;</span>
                    המשלוח צפוי להגיע אליכם עד
                    <span style={{ fontWeight: 500 }} class="free-shipping-green-text"> יום {nextTuesday()}</span>
                  </p>
                  <img src="/assets/images/checkout_check.svg" alt="משלוח חינם" />
                  </div>
                }
                </div>
                <InputCoupon imageCount={imageCount + imagesBlob.length} netPrice={netPrice + (imagesBlob.length * 45)} />

                <div className="price__table">
                  <div className="price__table--row" style={{ fontSize: "14px", fontWeight: 400 }}>
                    <div>₪ {netPrice + (imagesBlob.length * 45)}</div>
                    <div style={{ direction: "rtl" }}>{renderCounts()}</div>
                  </div>
                  <div className="price__table--row" style={{ fontSize: "14px", fontWeight: 400 }}>
                    {imageCount + imagesBlob.length <= 4 && <div>₪&nbsp;29.90</div>}
                    {imageCount + imagesBlob.length > 4 && <div>חינם</div>}
                    <div>משלוח</div>
                  </div>
                  <div className="price__table--row" style={{ fontWeight: "700", fontSize: "14px", color: "#B12704" }}>
                  <div>
                    ₪&nbsp;
                    {Number.isInteger(amountCharged) ? Math.floor(amountCharged) : amountCharged.toFixed(1)}
                  </div>
                  <div className="totalprice-sum">סה"כ</div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-checkout-pay-btn-container" style={{ fontSize: "14px" }}>
        {(!selectedAddress && !selectedPayment) && (
          <button
            type="button"
            className="btn cls pay-by-card-button "
            onClick={openAddressPopupMobile}
          >
            הזינו כתובת למשלוח
          </button>
        )}
        {(!selectedAddress && selectedPayment) && (
          <button
            type="button"
            className="btn cls pay-by-card-button "
            onClick={openAddressPopupMobile}
          >
            הזינו כתובת למשלוח
          </button>
        )}
        {(selectedAddress && !selectedPayment) && (
          <button
            type="button"
            className="btn cls pay-by-card-button "
            onClick={mySaveCardPopUp}
          >
            בחירת אמצעי תשלום
          </button>
        )}
        {isImagesLoading ? (
          (selectedAddress && selectedPayment) && (
            <button
              type="button"
              className="btn cls pay-by-card-button "
              disabled={isLoading}
              onClick={() => handleImagesLoading()}
            >
              אישור הזמנה
            </button>
          )
        ) : (
          (selectedAddress && selectedPayment) && (
            <button
              type="button"
              className="btn cls pay-by-card-button "
              disabled={isLoading}
              onClick={handleTranzilarPayment}
            >
              אישור הזמנה
            </button>
          )
        )}
      </div>
      {isLoading &&
        <div
          className="modal loaderbg"
          id="mainImageLoaderModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ display: isLoading }}
        >
          <div className="modal-dialog review-image-loader" role="document">
            <div className="loadingio-spinner-heart-btbrqan8295">
              <div className="ldio-kv0ui0pfesk">
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default CheckoutMobile;
