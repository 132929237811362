import "./style.css"
import React, {useState} from "react";
import {useRecoilValue, useSetRecoilState} from "recoil";
import useWindowDimensions from "../../../helpers/hooks/windowDemensions";
import {uploadPromptState} from "../../../Recoil/Atoms";

const UploadPrompt = ({show}) => {
  const setPrompt = useSetRecoilState(uploadPromptState);
  const [isClicked, setIsClicked] = useState(false);
  const {width} = useWindowDimensions();

  const handleOnClose = () => {
    setIsClicked(true);
    setTimeout(()=>{
      setPrompt(false);
    }, 1500)
  }

  return (
    <>
      <div className={`upload-prompt ${show ? "show" : ""} ${isClicked ? "clicked" : ""}`}>
        <div className={`upload-prompt-content ${isClicked ? "clicked" : ""}`}>
          <img src="/assets/images/frame-upload-prompt.svg" style={{width: "37px", height: "37px"}} alt="" />
          <div className={"upload-prompt-content-text"}>
            <p className={"upload-prompt-content-first-text"}>
              איזה כיף, באתם בזמן
            </p>
            <div className={"upload-prompt-content-second-text"}>
              {width < 767 ? (
                <>
                  <div className={"upload-prompt-content-second-text-mobile"}>
                    <p>
                      ₪399
                    </p>
                    <p>
                      ₪540
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    ₪399
                  </p>
                  <p>
                    ₪540
                  </p>
                </>
              )}
              <p>
                מבצע נובמבר - 26% פחות בקניית 12 בלנדס
              </p>
            </div>
          </div>
          <div className={"upload-prompt-content-button"}>
            <button
              onClick={() => {handleOnClose()}}
            >
              <p>הבנתי, תודה</p>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadPrompt;